import { createGlobalStyle } from "styled-components";
import "@fontsource/inter";
import "@fontsource/inter/700.css";
import "@fontsource/inter/500.css";
import dataIcon from "../images/Data.svg";
import businessIcon from "../images/Business.svg";
import presentIcon from "../images/Frontend.svg";
import balanceIcon from "../images/balance.svg";
import falconIcon from "../images/falcon.svg";
import cellsIcon from "../images/cells.svg";
import shieldIcon from "../images/shield.svg";

const GlobalStyles = createGlobalStyle`
::placeholder,
.input.placeholder{
  color: rgba(0,0,0,0.2) !important;
  opacity: 1; 
}
:root {
    --sansSerif: "Inter", sans-serif;
    --h1: 2rem;
    --h2: 1.8rem;
    --h3: 1.5rem;
    --h4: 1.25rem;
    --h5: 1.15rem;
    --h6: 1rem;
    --footerMenuItem: 0.85rem;
    --para: 1rem;
    --spacing: 1rem;

    @media (min-width: 768px) {
      --h1: 2.6rem;
      --h2: 2.4rem;
      --h3: 1.75rem;
      --h4: 1.5rem;
      --h5: 1.25rem;
      --h6: 1.1rem;
      --footerMenuItem: 1rem;
      --para: 1.1rem;
    }

    @media (min-width: 1200px) {
      --h1: 3rem;
      --h2: 2.8rem;
      --h3: 2rem;
      --h4: 1.75rem;
      --h5: 1.35rem;
      --para: 1.15rem;
    }
  }
* {
    box-sizing: border-box;
}
#gatsby-focus-wrapper{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.layout{
  flex: 1;
}
body {
  font-family: 'Inter', sans-serif;
    color: #262730;
    font-size: 14px;
    background: white;
    b{
      font-weight: 700;
    }
}
.paralax{
  perspective: 40px;
  overflow: hidden;
  &:after{
    top: -10% !important;
    height: 140% !important;
    transform: translateZ(-10px) scale(1.25);
  }
}

  .content-container {
    margin: 0 auto;
    width: 100%;
    max-width: 500px;

    @media (min-width: 1080px) {
      max-width: 650px;
    }
  }
  .lead-illustr,
  .lead-text {
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 35px;
    width: 100%;
    &.centered{
      padding-top: 100px;
    }
    h1{
      text-align: center;
      font-weight: bold;
      color: white;
    }
    p{
      text-align: center;
      color: white;
    }
    .flex{
      padding-left: 6px;
      flex-wrap: wrap;
      justify-content: center;
      p{
        padding-right: 6px;
        display: inline;
      }
    }
    > p{
      max-width: 540px;
    }
    .sipka {
      height: 127px;
    }
    .flex{
      @media(max-width: 767px){
        display: block;
        text-align: center;
        p{
          display: inline;
        }
      }
    }
  }

  .lead-text {
    h1{
      color: inherit;
    }
    p{
      color: inherit;
    }
  }
    .flex-container {
    display: flex;

    &.trio-block {
      flex-direction: column;
      justify-content: center;

      @media (min-width: 992px) {
        flex-direction: row;
      }
    }
  }

  .section-padding {
    padding: 100px 30px;
  }
  h3{
    font-size: 13px;
    color: #26273033;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  h4{
    font-size: 20px;
    letter-spacing: 2px;
  }
  .text-center,
  .centered {
    text-align: center;
    h2, p, ul{
      max-width: 640px;
      margin-left: auto;
      margin-right: auto;
    }
    ul{
      text-align: left;
    }
  }

  .flex{
    display:flex;
    &.justify-center{
      justify-content: center;
    }
    &.page{
      flex-wrap: wrap;
      justify-content: center;
    }
    .full-width{
      width: 100%;
    }
    @media (max-width: 991px) {
      display: block;
      text-align: center;
    }
  }
  .height-100{
    min-height: 100vh;
  }
  .overlay{
    transition: all .3s;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    visibility: hidden;
    background-color: #ffffff00;
    transition: background-color .3s;
    &.active{
      z-index: 3;
      visibility: visible;
      background-color: #ffffffb3;
      cursor: pointer;
    }
  }
  .section{
    max-width: 1160px;
    min-height: 100vh;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
    .rounded{
      min-height: 400px;
    }
    &.html{
      min-height: 400px;
      width: 100%;
      padding-top: 0;
      h2:not(.centered h2){
        margin-top: 2em;
        margin-bottom: 1em;
      }
      .col-2{
        margin-bottom: 8em;
      }
      .centered{
        margin-top: 60px;
        margin-bottom: 60px;
        justify-content: center;
        padding-top: 0;
        max-width: 900px;
        flex-wrap: wrap;
      }
      ul{
        max-width: 900px;
        margin: 0 auto;
      }
      p{
        max-width: 100%;
      }

      .cards{
        flex-wrap: wrap;
        margin: 0 -10px;
        > * {
          cursor: pointer;
          transition: transform .3s;
          background-color: rgb(243, 244, 246);
          border-radius: 26px;

          &:hover{
            transform: scale(1.03);
          }
        }
        + h2 {
          margin-bottom: 0 !important;
          +p:empty{
            display: none;
          }
        }
        .card-1{
          flex: 0 0 calc(33% - 20px);
          align-items: center;
          padding: 20px;
          margin: 10px;
          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: flex-start;
        }
        .card-2{
          flex: 0 0 calc(66% - 40px);
          align-items: center;
          padding: 20px;
          margin: 10px;
          display: flex;
          flex-direction: column;
          justify-content: end;
          align-items: flex-start;
        }
        h4{
          letter-spacing: initial;
          margin: 0;
          color: rgb(34, 67, 235);
        }
      }
      .big-image{
        display: flex;
        flex-direction: column;
        margin: 0;
        position: relative;
        color: white;
        width: 100%;
        max-width: 100%;
        min-height: 500px;
        text-align: center;
        transition: min-height .3s;
        justify-content: center;
        margin-bottom: 100px;


        img{
          margin-bottom: 0;
          margin-top: 0;
          position: absolute;
          z-index: -1;
          width: 100%;
        }
        h1{
          font-size: 60px;
          margin: 0;
          transition: font-size 0.3s;
          @media(max-width:640px){
            font-size: 40px;
          }
        }
        p{
          margin-top: 0;
         font-size: 30px;
        }
        .button{
            background-color: white;
            cursor: pointer;
            position: relative;
            padding: 0 50px;
            text-align: center;
            line-height: 68px;
            color: #262730;
            font-size: 18px;
            font-weight: bold;
            text-decoration: none;
            box-sizing: border-box;
            border-radius: 14px;
            border: none;
            z-index: 1;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            transition: 0.3s;
            &:hover {
              opacity: 0.8;
            }
          
        }
        @media(max-width: 991px){
          min-height: 400px;
        }
        @media(max-width: 767px){
          min-height: 0;
          margin-bottom: 0;
        }
      }
        table{
          width: 100%;
          text-align: center;
          table-layout: fixed;
          .counter {
            strong{
              font-size: 130px;
              line-height: 100px;
              color: #2243EB;
              @media(max-width: 991px){
                font-size: 50px;
                line-height: 50px;
              }
            }
            &.plus strong:after{
              content: "+";
              display: inline;
            }
          }
          td{
            vertical-align: top;
            padding-right: 30px;  
          }
          p{
            font-size: 24px;
            @media(max-width: 991px){
              font-size: 16px;
            }
          }
        }
        .align-left{
          table{
            text-align: left;
            td{
              padding-right: 30px;
              @media (max-width: 767px) {
                display: block;
                padding-right: 0;
              }
            }
          }
        }
    }
    &.rounded{
      border-radius: 26px;
      overflow: hidden;
      min-height: 400px;
    }
    &.rounded{
      @media (max-width: 1450px) {
        margin-left: 1em;
        margin-right: 1em;
        width: calc(100% - 2em);
      }
    }
    .shadow{
      /*filter: drop-shadow(0px 40px 80px rgba(122, 146, 153, 0.52));*/
    }
    .centered{
      max-width: 650px;
      margin: 0 auto;
    }
  }
  .centeredSmall{
    max-width: 410px;
    width: calc(50% - 30px);
    margin: 70px 35px;;
    min-height: 570px;
    border-radius: 25px;
    overflow: hidden;
    align-self: center;
    .textBlock{
      text-align: center;
      height: 100%;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    @media (max-width: 767px) {
      width: 90%;
      max-width: 400px;
      margin: 20px 0;
    }
  }
  .imageSmall{
    max-width: 380px;
    width: calc(50% - 30px);
    margin: 30px 15px;
    min-height: 570px;
    border-radius: 25px;
    overflow: hidden;
    align-self: center;
    @media (max-width: 767px) {
      width: 90%;
      max-width: 400px;
      margin: 20px 0;
    }
  }
  #mainContent{
    width: 100%;
  }
  .container{
    max-width: 1400px;
    margin: 0 auto;
    padding: 40px;
  }
  .grecaptcha-badge { visibility: hidden; }
  .sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important; /* 2 */
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important; /* 3 */
  }
  .cta{
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
  }
  .sr-only-focusable:focus,
  .sr-only-focusable:active {
    clip: auto !important;
    -webkit-clip-path: none !important;
    clip-path: none !important;
    height: auto !important;
    margin: auto !important;
    overflow: visible !important;
    width: auto !important;
    white-space: normal !important;
  }
  .hide-sm{
    @media (max-width: 767px){
      display: none;
    }
  }
  #block-vyrocie .logo{
    height: 44px;
  }
  .icon-data p,
  .icon-present p,
  .icon-business p{
    width: 150px;
    height: 150px;
    background-color: #2243eb;
    margin-top: 50px;
    background-size: 80px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    background-image: url(${dataIcon});
    margin-left: auto;
    margin-right: auto;
  }

  .icon-present p{
    background-image: url(${presentIcon});
  }
  .icon-business p{
    background-image: url(${businessIcon});
  }
  .buttons{
    margin: 0 -70px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .btn{
      border-radius: 24px;
      padding: 0 20px;
      text-align: center;
      margin-bottom: 0;
      background-color: rgb(235, 34, 82);
      display: inline-block;
      margin: 3px;
      background-repeat: no-repeat;
      background-position: calc(100% - 13px) center;
      min-width: 260px;
      flex-grow: 0;
      
      p{
        margin: 8px 0;
      }
      a{
        color: rgb(255, 220, 204);
        text-decoration: none;
      }
      &.kodex{
        padding-right: 40px;
        background-image: url(${balanceIcon});
      }
      &.korupcia{
        padding-right: 40px;
        background-image: url(${shieldIcon});
      }
      &.compliance{
        padding-right: 40px;
        background-image: url(${cellsIcon});
        background-position-x: 94%;
      }
      &.company{
        padding-right: 40px;
        background-image: url(${falconIcon});
        background-size: 24px;
      }
    }
  }
`;
export default GlobalStyles;
